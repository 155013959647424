import React, { useEffect, useRef, useState } from "react";
import * as styles from './Carousel.module.scss';
import { PortableText } from "@portabletext/react";
import { CSSTransition } from 'react-transition-group';
import ScrollObserver from '../../ScrollObserver/ScrollObserver'

const Carousel = (props) => {
    const { data } = props;
    const [activeSlide, setActiveSlide] = useState(0);
    const [hovered, setHovered] = useState(false);
    const [isVisible, setIsVisible] = useState(false);
    // console.log('isVisible', isVisible)

    //Timer will tick up every (intervalDelay / 100) seconds
    //Once timer reaches maxTimer, the slide changes and timer goes back to 0;
    const [timer, setTimer] = useState(0);
    const timerMax = 125
    const intervalDelay = 40
    // console.log(data);

    const useInterval = (callback, delay) => {
        const savedCallback = useRef();
        // Remember the latest callback.
        useEffect(() => {
          savedCallback.current = callback;
        }, [callback]);
        // Set up the interval.
        useEffect(() => {
          function tick() {
            savedCallback.current();
          }
          if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
          }
        }, [delay]);
      }

    useInterval(() => {
        // Don't update the timer if the component is not visible
        if (!isVisible) {
          return; 
        }
        if(timer === timerMax) {
            if(data.items.length - 1 <= activeSlide) {
                setActiveSlide(0);
            } else {
                setActiveSlide(activeSlide + 1);
            }
        }
        if(timer >= timerMax) {
            setTimer(0);
        } else
        if(!hovered) {
            setTimer(timer + 1);
        }
        // console.log(timer >= timerMax);
        // console.log(timer);
    }, intervalDelay);

    useEffect(() => {

    }, [activeSlide]);

    return (
        <ScrollObserver setIsVisible={setIsVisible}>
          <div
              className={styles.carouselContainer}
              // onMouseEnter={() => setHovered(true)}
              // onMouseLeave={() => setHovered(false)}
          >
              <div className={styles.carouselSlide}>
              {data?.items?.map((item, index) => (
                  <CSSTransition key={index} in={activeSlide === index} timeout={1000} classNames="slide-text" unmountOnExit>
                  <div className={styles.slideText}>
                      <div className={styles.icon}>
                      <img src={item?.titleIcon.asset.url} alt={'header'} />
                      </div>
                      <p className={styles.title}>{item?.title}</p>
                      <div className={styles.descriptionText}>
                      <PortableText value={item?._rawDescription} />
                      </div>
                  </div>
                  </CSSTransition>
              ))}
              {data?.items?.map((item, index) => (
                  <CSSTransition
                  key={index}
                  in={activeSlide === index}
                  timeout={1000}
                  classNames="background-image"
                  unmountOnExit
                  >
                  <div className={styles.backgroundImage}>
                      <img src={item?.backgroundImage?.asset?.url} alt={'background'} />
                  </div>
                  </CSSTransition>
              ))}
              </div>
              <div className={styles.carouselPages}>
              {data?.items?.map((page, index) => (
                  <button
                  className={styles.page + (index === activeSlide ? ' ' + styles.active : '')}
                  key={index}
                  onClick={() => {
                      setActiveSlide(index)
                      setTimer(0)
                  }}
                  onMouseEnter={() => setHovered(true)}
                  onMouseLeave={() => setHovered(false)}
                  onTouchStart={() => {setHovered(true); setActiveSlide(index); setTimer(0)}}
                  onTouchEnd={() => setHovered(false)}
                  >
                  <div
                      className={styles.pageIcon + (index === activeSlide ? ' ' + styles.active : '')}
                      dangerouslySetInnerHTML={{ __html: page.scrollIcon }}
                  ></div>
                  {index === activeSlide && (
                      <div
                      className={styles.activeBorder}
                      style={{
                          background:
                          'conic-gradient(#FFC532 0% ' +
                          (100 * timer) / timerMax +
                          '%, transparent ' +
                          (100 * timer) / timerMax +
                          '%)',
                      }}
                      />
                  )}
                  </button>
              ))}
              </div>
          </div>
      </ScrollObserver>
    )
}

export default Carousel