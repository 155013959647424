import React from 'react'
import useMediaQuery from '../../../utils/useMediaQuery'
import * as styles from './StaticTable.module.scss'

const Asterisks = (props) => {
  const { everest, championAward } = props

  if (everest && championAward) {
    return <span className={styles.bothAwards}>***</span>
  }
  return (
    <span>
      {everest && <span className={styles.everest}>*</span>}
      {championAward && <span className={styles.championAward}>**</span>}
    </span>
  )
}

// ListItem for each hospital in StaticTable

const ListItem = (props) => {
  const { item, index, isMobile } = props
  return (
    <li className={styles.listItem} key={index}>
      <h3 className={styles.hospital}>
        {item.hospital}
        <Asterisks everest={item.everest} championAward={item.championAward} />
      </h3>
      {isMobile ? <br /> : <span>&nbsp;&nbsp;-&nbsp;&nbsp;</span>}
      <p className={styles.city}>{item.city}</p>
    </li>
  )
}

const StaticTable = (props) => {
  const { data } = props
  // use to add <br/> on mobile
  const isMobile = useMediaQuery('(max-width: 768px)')

  // Split the data into two equal columns
  const middle = Math.ceil(data.length / 2)
  const leftColumn = data.slice(0, middle)
  const rightColumn = data.slice(middle)

  return (
    <div className={styles.container}>
      <div className={styles.leftColumn}>
        <ul>
          {leftColumn.map((item, index) => {
            return <ListItem item={item} index={index} isMobile={isMobile} key={index} />
          })}
        </ul>
      </div>
      <div className={styles.rightColumn}>
        <ul>
          {rightColumn.map((item, index) => {
            return <ListItem item={item} index={index} isMobile={isMobile} key={index} />
          })}
        </ul>
      </div>
    </div>
  )
}

export default StaticTable
